<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import Multiselect from "vue-multiselect";

import FormCategory from "@/components/forms/form-category/index";
import FormAuthor from "@/components/forms/form-author/index";
import FormContentContent from "@/components/forms/form-course/form-content-course/index";

import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { required } from "vuelidate/lib/validators";

import {
  defineFormData,
  authorMethods,
  courseMethods,
  categoryMethods,
  notificationMethods,
  skillMethods,
} from "@/state/helpers";

/**
 * Course Form component
 */
export default {
  page: {
    title: "Cadastrar Curso",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    FormWizard,
    TabContent,
    FormAuthor,
    FormCategory,
    FormContentContent
  },
  data() {
    return {
      title: "Cadastrar Curso",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Cursos",
          href: "/courses",
        },
        {
          text: "Cadastrar Curso",
          active: true,
        },
      ],
      form: {
        title: "",
        author: "",
        short_description: "",
        description: "",
        category: "",
        hability: "",
        allowedQuestions: {
          key: "Deve assistir as aulas anteriores",
          value: true,
        },
        show_answers_quiz: { key: "Sim", value: true },
        show_case: { key: "Não", value: false },
        order: 1,
        max_students: 0,
        accessTime: { key: "1 Mês", value: 1 },
        display_branches: false,
        workload: "",
        value: "",
        discount_value: "",
        video_url: "",
        restricted: false,
      },
      options: [        
        {
          key: "Deve assistir as aulas anteriores",
          value: true,
        },
        {
          key: "Não precisa ter visto as aulas anteriores",
          value: false,
        },
      ],
      yesOrNotOptions: [
        {
          key: "Sim",
          value: true,
        },
        {
          key: "Não",
          value: false,
        },
      ],
      accessTimeOptions: [
        {
          key: "1 Mês",
          value: 1,
        },
        {
          key: "2 Mêses",
          value: 2,
        },
        {
          key: "3 Mêses",
          value: 3,
        },
        {
          key: "4 Mêses",
          value: 4,
        },
        {
          key: "5 Mêses",
          value: 5,
        },
      ],
      showModalAuthor: false,
      showModalCategory: false,
      authors: [],
      skills: [],
      categories: [],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    form: {
      title: { required },
      author: { required },
      category: { required },
      sequence: { required },
      max_students: { required },
      accessTime: { required },
      short_description: { required },
      description: { required },
      workload: { required },
    },
  },
  props: ['id'],
  created() {
    if(this.id) {
      this.getById();
    }
    this.loadData();
  },
  methods: {
    ...authorMethods,
    ...categoryMethods,
    ...courseMethods,
    ...notificationMethods,
    ...skillMethods,    
    async getById() {
      this.loading = true; 
      let dadosCurso = await this.getCourse(this.id)
      this.form = dadosCurso?.data;
      this.loading = false;
    },

    async loadData() {
      await Promise.all([
        this.loadAuthors(),
        this.loadCategories(),
        this.loadSkills(),
      ]);
    },

    async loadAuthors() {
      this.showModalAuthor = false;
      let registros = await this.getAllAuthor();
      this.authors = registros?.data || [];
    },

    async loadCategories() {
      this.showModalCategory = false;
      let registros = await this.getAllCategory();
      this.categories =  registros?.data || [];
    },

    async loadSkills() {
      this.showModalCategory = false;
      let registros = await this.getAllSkill();
      this.skills =  registros?.data || [];
    },

    async formSubmit() {
      this.submitted = true;
      this.loadingForm = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        await this.saveCourse(defineFormData(this.form)).then(() => {
          this.$router.go(-1);
          this.loadingForm = false;
        });
      } else {
        this.loadingForm = false;
      }
    },

    async validateStepOne() {
      this.typesubmit = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.typesubmit = false;
        return true;
      } else {
        return false;
      }
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :enableCad="false" />
    <div class="row">
      <div class="col-xl">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-3">Formulário de Cadastro do Curso</h4>
            <b-spinner
              v-if="loading"
              label="Spinning"
              variant="info"
              class="m-1"
            ></b-spinner>
            <form-wizard
              v-show="!loading"
              @on-complete="formSubmit"
              shape="tab"
              color="#626ed4"
              nextButtonText="Próximo"
              backButtonText="Anterior"
              finishButtonText="Cadastrar"
            >
              <tab-content icon="fas fa-chart-line" v-if="form?.id" title="Conteúdo do curso">
                <div class="row">
                  <FormContentContent v-bind:editingCourse="form"></FormContentContent>
                </div>
                <!-- end row -->
              </tab-content>
              <tab-content 
                :before-change="validateStepOne"
                icon="fas fa-pencil-alt" title="Básico">
                <div class="row">
                  <div class="col-12">
                    <form action="#" @submit.prevent="form">
                      <div class="row mb-4">
                        <label class="col-md-3 form-label"
                          >Título do Curso
                          <span class="required">*</span></label
                        >
                        <div class="col-md-9">
                          <input
                            v-model="form.title"
                            type="text"
                            class="form-control"
                            placeholder="Dígite o título do curso"
                            name="name"
                            :class="{
                              'is-invalid': typesubmit && $v.form.title.$error,
                            }"
                          />
                          <div
                            v-if="typesubmit && $v.form.title.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.form.title.required"
                              >Este campo é obrigatorio.</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <label class="col-md-3 form-label"
                          >Autor <span class="required">*</span></label
                        >
                        <div class="col-md-9">
                          <div class="row">
                            <div class="col-9 col-sm-10 col-md-7 col-xl-8">
                              <multiselect
                                v-model="form.author"
                                :options="authors"
                                placeholder="Selecione o autor do curso"
                                label="name"
                                track-by="id"
                                :class="{
                                  'is-invalid':
                                    typesubmit && $v.form.author.$error,
                                }"
                              ></multiselect>
                              <div
                                v-if="typesubmit && $v.form.author.$error"
                                class="invalid-feedback"
                              >
                                <span v-if="!$v.form.author.required"
                                  >Este campo é obrigatorio.</span
                                >
                              </div>
                            </div>
                            <div class="col-3 col-sm-2 col-md-5 col-xl-4">
                              <b-button
                                class="w-100"
                                variant="success"
                                @click="showModalAuthor = !showModalAuthor"
                                >+ Cadastrar Autor</b-button
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <label class="col-md-3 form-label"
                          >Pequena Descrição <span class="required">*</span></label
                        >
                        <div class="col-md-9">
                          <textarea
                            v-model="form.short_description"
                            class="form-control"
                            name="textarea"
                            :class="{
                              'is-invalid':
                                typesubmit && $v.form.short_description.$error,
                            }"
                          ></textarea>
                          <div
                            v-if="typesubmit && $v.form.short_description.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.form.short_description.required"
                              >Este campo é obrigatorio.</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <label class="col-md-3 form-label">Descrição <span class="required">*</span></label>
                        <div class="col-md-9">
                          <textarea
                            v-model="form.description"
                            class="form-control"
                            name="textarea"
                            rows="5" 
                            :class="{
                              'is-invalid':
                                typesubmit && $v.form.description.$error,
                            }"
                          ></textarea>
                          <div
                            v-if="typesubmit && $v.form.description.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.form.description.required"
                              >Este campo é obrigatorio.</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <label class="col-md-3 form-label"
                          >Categoria <span class="required">*</span></label
                        >
                        <div class="col-md-9">
                          <div class="row">
                            <div class="col-9 col-sm-10 col-md-7 col-xl-8">
                              <multiselect
                                v-model="form.category"
                                :options="categories"
                                placeholder="Selecione a categoria do curso"
                                label="title"
                                track-by="id"
                                :class="{
                                  'is-invalid':
                                    typesubmit && $v.form.category.$error,
                                }"
                              ></multiselect>
                              <div
                                v-if="typesubmit && $v.form.category.$error"
                                class="invalid-feedback"
                              >
                                <span v-if="!$v.form.category.required"
                                  >Este campo é obrigatorio.</span
                                >
                              </div>
                            </div>
                            <div class="col-3 col-sm-2 col-md-5 col-xl-4">
                              <b-button
                                class="w-100"
                                variant="success"
                                @click="showModalCategory = !showModalCategory"
                                >+ Cadastrar Categoria</b-button
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <label class="col-md-3 form-label"
                          >Para o Aluno Realizar as Questões</label
                        >
                        <div class="col-md-9">
                          <multiselect
                            v-model="form.allowedQuestions"
                            :options="options"
                            label="key"
                            track-by="value"
                            placeholder="Selecione uma Opção"
                          ></multiselect>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <label class="col-md-3 form-label"
                          >Exibir Respostas de Questionarios</label
                        >
                        <div class="col-md-9">
                          <multiselect
                            v-model="form.showAnswer"
                            :options="yesOrNotOptions"
                            label="key"
                            track-by="value"
                            placeholder="Selecione uma Opção"
                          ></multiselect>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <label class="col-md-3 form-label"
                          >Ordem <span class="required">*</span></label
                        >
                        <div class="col-md-4">
                          <b-form-input
                            v-model="form.sequence"
                            type="number"
                            :class="{
                              'is-invalid':
                                typesubmit && $v.form.sequence.$error,
                            }"
                          ></b-form-input>
                          <div
                            v-if="typesubmit && $v.form.sequence.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.form.sequence.required"
                              >Este campo é obrigatorio.</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <label class="col-md-3 form-label"
                          >Total Máximo de Alunos
                          <span class="required">*</span></label
                        >
                        <div class="col-md-4">
                          <b-form-input
                            v-model="form.max_students"
                            type="number"
                            :class="{
                              'is-invalid':
                                typesubmit && $v.form.max_students.$error,
                            }"
                          ></b-form-input>
                          <div
                            v-if="typesubmit && $v.form.max_students.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.form.max_students.required"
                              >Este campo é obrigatorio.</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <label class="col-md-3 form-label"
                          >Tempo de Acesso ao Curso
                          <span class="required">*</span></label
                        >
                        <div class="col-md-4">
                          <multiselect
                            v-model="form.accessTime"
                            :options="accessTimeOptions"
                            label="key"
                            track-by="value"
                            placeholder="Selecione uma Opção"
                            :class="{
                              'is-invalid':
                                typesubmit && $v.form.accessTime.$error,
                            }"
                          ></multiselect>
                          <div
                            v-if="typesubmit && $v.form.accessTime.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.form.accessTime.required"
                              >Este campo é obrigatorio.</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <label class="col-md-3 form-label"
                          >Carga Horária <span class="required">*</span></label
                        >
                        <div class="col-md-4">
                          <b-form-input
                            v-model="form.workload"
                            class="form-control"
                            type="number"
                            :class="{
                              'is-invalid':
                                typesubmit && $v.form.workload.$error,
                            }"
                          ></b-form-input>
                          <div
                            v-if="typesubmit && $v.form.workload.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.form.workload.required"
                              >Este campo é obrigatorio.</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <label class="col-md-3 form-label"></label>
                        <div class="col-md-4">
                          <input
                            v-model="form.display_branches"
                            class="form-check-input"
                            type="checkbox"
                            id="invalidCheck"
                            required=""
                          />
                          <label
                            class="form-check-label"
                            style="margin-left: 10px"
                            for="invalidCheck"
                          >
                            Curso em Destaque?
                          </label>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <label class="col-md-3 form-label"></label>
                        <div class="col-md-4">
                          <input                          
                            v-model="form.restricted"
                            class="form-check-input"
                            type="checkbox"
                            id="invalidCheck"
                            required=""
                          />
                          <label
                            class="form-check-label"
                            style="margin-left: 10px"
                            for="invalidCheck"
                          >
                            Curso Restrito?
                          </label>
                        </div>
                      </div>
                    </form>
                  </div>
                  <!-- end col -->
                </div>
                <!-- end row -->
              </tab-content>
              <tab-content icon="fas fa-chart-line" title="Resultados">
                <div class="row">
                  <label class="col-md-3 form-label">Habilidades</label>
                  <div class="col-md-9">
                    <multiselect
                      v-model="form.hability"
                      :options="skills"
                      label="title"
                      track-by="id"
                      placeholder="Selecione uma habilidade para o curso"
                    ></multiselect>
                  </div>
                  <!-- end col -->
                </div>
                <!-- end row -->
              </tab-content>
              <tab-content icon="fas fa-dollar-sign" title="Valores">
                <div class="row justify-content-center">
                  <div class="col-md-10 col-xl-8">
                    <div class="form-group row mb-3">
                      <div class="offset-md-4 col-md-8">
                        <div class="custom-control custom-checkbox">
                          <input
                            v-b-toggle.price-accordion
                            class="form-check-input"
                            type="checkbox"
                            id="invalidCheck"
                            required=""
                          />
                          <label
                            class="custom-control-label"
                            style="margin-left: 10px"
                            for="is_free_course"
                          >
                            Curso gratuito?
                          </label>
                        </div>
                      </div>
                    </div>
                    <b-collapse
                      class="paid-course-stuffs"
                      id="price-accordion"
                      visible
                      accordion="my-accordion"
                    >
                      <div class="row mb-4">
                        <label class="col-md-4 form-label"
                          >Valor do Curso (R$)</label
                        >
                        <div class="col-md-8">
                          <input
                            v-model="form.value"
                            v-mask="'###.###.###.###.###,##'"
                            type="text"
                            class="form-control"
                            placeholder="Informe o valor do curso"
                            name="value"
                          />
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <div class="offset-md-4 col-md-8">
                          <div class="custom-control custom-checkbox">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="invalidCheck"
                              required=""
                            />
                            <label
                              class="custom-control-label"
                              style="margin-left: 10px"
                              for="is_free_course"
                            >
                              Esse Curso tem Disconto?
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <label class="col-md-4 form-label"
                          >Valor com Desconto (R$)</label
                        >
                        <div class="col-md-8">
                          <input
                            v-model="form.discountValue"
                            v-mask="'###.###.###.###.###,##'"
                            type="text"
                            class="form-control"
                            placeholder="Informe o valor do curso"
                            name="value"
                          />
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                </div>
                <!-- end row -->
              </tab-content>
              <tab-content icon="fas fa-photo-video" title="Apresentação">
                <div class="row justify-content-center">
                  <div class="col-md-10 col-xl-8">
                    <b-collapse
                      class="paid-course-stuffs"
                      id="price-accordion"
                      visible
                      accordion="my-accordion"
                    >
                      <div class="row mb-4">
                        <label class="col-md-4 form-label"
                          >Link dos Vídeo de Apresentação</label
                        >
                        <div class="col-md-8">
                          <input
                            v-model="form.video_url"
                            type="text"
                            class="form-control"
                            placeholder="Ex: https://www.youtube.com/watch?v=oBtf8Yglw2w"
                            name="value"
                          />
                        </div>
                      </div>
                      <div class="row mb-4">
                        <label class="col-md-4 form-label"
                          >Exibir na Vitrine</label
                        >
                        <div class="col-md-8">
                          <multiselect
                            v-model="form.show_case"
                            :options="yesOrNotOptions"
                            label="key"
                            track-by="value"
                            placeholder="Selecione se o curso aparece na vitrine"
                          ></multiselect>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <label class="col-md-4 form-label"
                          >Miniatura Curso</label
                        >
                        <div class="col-lg-6 col-xl-3">
                          <b-card
                            :img-src="
                              require('@/assets/images/small/img-3.jpg')
                            "
                            img-alt="Card image"
                            img-top
                            class="shadow-sm mb-5 bg-white rounded"
                          >
                            <b-card-text>
                              <i class="fas fa-camera"></i> Miniatura do curso
                              (600x600).
                            </b-card-text>
                          </b-card>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                </div>
                <!-- end row -->
              </tab-content>
              <tab-content icon="far fa-check-circle" title="Finalizar">
                <div class="row">
                  <div class="col-12">
                    <div class="text-center">
                      <h2 class="mt-0">
                        <i class="mdi mdi-check-all"></i>
                      </h2>
                      <h3 class="mt-0">Finalizar Cadastro!</h3>

                      <p class="w-75 mb-2 mx-auto">
                        Tudo Pronto! Agora Clique em "Cadastrar" para Finalizar
                        o Cadastro do Curso.
                      </p>
                    </div>
                  </div>
                  <!-- end col -->
                </div>
              </tab-content>
            </form-wizard>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
    <b-modal
      v-model="showModalCategory"
      id="modal-dialog"
      title="Cadastrar Categoria"
      title-class="font-18"
      hide-footer
    >
      <FormCategory @newCadSuccess="loadCategories"></FormCategory>
    </b-modal>
    <b-modal
      v-model="showModalAuthor"
      id="modal-dialog"
      title="Cadastrar Autor"
      title-class="font-18"
      hide-footer
    >
      <FormAuthor @newCadSuccess="loadAuthors"></FormAuthor>
    </b-modal>
  </Layout>
</template>
