<script>
import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";
import { defineFormData, authorMethods, notificationMethods } from "@/state/helpers";

import FormButtonSalvar from "@/components/forms/form-button-salvar/index";

/**
 * Author Form component
 */
export default {
  page: {
    title: "Formulário de Autores",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { FormButtonSalvar },
  props: ["editingAuthor"],
  data() {
    return {
      form: {
        name: "",
        surname: "",
        email: "",
        description: "",
        image: "",
      },
      image: {},
      loadingForm: false,
      submitted: false,
      submit: false,
    };
  },
  validations: {
    form: {
      name: { required },
      surname: { required },
      description: { required },
    },
  },
  methods: {
    ...authorMethods,
    ...notificationMethods,

    async formSubmit() {
      this.submitted = true;
      this.loadingForm = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        await this.saveAuthor(
          defineFormData({ ...this.form, image: this.image ?? this.form.image }))
          .then(() => {
            this.emitCadSuccess(true);
            this.loadingForm = false;
        });
      } else {        
        this.loadingForm = false;
      }
    },
    
    handleFileChange(event) {
      this.image = event.target.files[0];
    },
    
    emitCadSuccess(event) {
      this.$emit("newCadSuccess", event);
    },
    appendForm(author) {
      this.form = { ...author };
    },
  },
  watch: {
    editingAuthor: function (author) {
      this.appendForm(author);
    },
  },
};
</script>

<template>
  <form class="needs-validation" @submit.prevent="formSubmit">
    <div class="row">
      <div class="col-lg-12">
        <div class="mb-3">
          <label class="form-label" for="validationCustom01"
            >Nome do Autor <span class="required">*</span></label
          >
          <input
            id="validationCustom01"
            v-model="form.name"
            type="text"
            class="form-control"
            placeholder="Digite o nome do autor"
            value="Mark"
            :class="{
              'is-invalid': submitted && $v.form.name.$error,
            }"
          />
          <div v-if="submitted && $v.form.name.$error" class="invalid-feedback">
            <span v-if="!$v.form.name.required">O campo é obrigatório.</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="mb-3">
          <label class="form-label" for="validationCustom01"
            >Sobrenome <span class="required">*</span></label
          >
          <input
            id="validationCustom01"
            v-model="form.surname"
            type="text"
            class="form-control"
            placeholder="Digite o sobrenome do autor"
            value="Mark"
            :class="{
              'is-invalid': submitted && $v.form.surname.$error,
            }"
          />
          <div
            v-if="submitted && $v.form.surname.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.form.surname.required">O campo é obrigatório.</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="mb-3">
          <label class="form-label" for="validationCustom01">Email </label>
          <input
            id="validationCustom01"
            v-model="form.email"
            type="text"
            class="form-control"
            placeholder="Digite o email do autor"
            value="Mark"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="mb-3">
          <label class="form-label" for="validationCustom02"
            >Biografia <span class="required">*</span></label
          >
          <textarea
            v-model="form.description"
            class="form-control"
            name="textarea"
            rows="8"
            :class="{
              'is-invalid': submitted && $v.form.description.$error,
            }"
          ></textarea>
          <div
            v-if="submitted && $v.form.description.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.form.description.required"
              >O campo é obrigatório.</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="mb-3">
          <label class="form-label" for="validationCustom02"
            >Imagem do Autor</label
          >
          <input
            id="validationCustom02"
            type="file"
            @change="handleFileChange"
            class="form-control"
            placeholder="Ex: 1"
            value="Otto"
          />
        </div>
      </div>
    </div>
    <FormButtonSalvar
      :parent="this"
      :loading="loadingForm"
      :text="form.id ? 'Salvar' : 'Cadastrar'"
    ></FormButtonSalvar>
  </form>
</template>
