<script>
import {
  categoryMethods,
  moduleMethods,
  notificationMethods,
} from "@/state/helpers";
import FormLessonCourse from "@/components/forms/form-course/form-lesson-course/index";
import FormModuleCourse from "@/components/forms/form-course/form-module-course/index";
import FormQuestionDissertationCourse from "@/components/forms/form-course/form-question-dissertation-course/index";
import FormQuestionAttachmentCourse from "@/components/forms/form-course/form-question-dissertation-course/index";
import FormQuizzesCourse from "@/components/forms/form-course/form-quizzes-course/index";

/**
 * Course Content Form component
 */
export default {
  components: {
    FormLessonCourse,
    FormModuleCourse,
    FormQuestionAttachmentCourse,
    FormQuestionDissertationCourse,
    FormQuizzesCourse,
  },
  props: ["editingCourse"],
  data() {
    return {
      courseId: null,
      editingModule: undefined,
      showModalModules: false,
      showModalClasses: false,
      showModalQuestionDissertation: false,
      showModalQuestionAttachment: false,
      showModalQuizzes: false,
      loading: false,
      loadingModules: false,
    };
  },
  methods: {
    ...categoryMethods,
    ...moduleMethods,
    ...notificationMethods,

    async loadModules() {
      this.hiddenModals();
      this.loadingModules = true;
      const registros = await this.getAllModule({
        courseId: this.editingCourse.id,
      });
      this.tableData = registros?.data || [];
      this.loading = false;
      this.totalRows = this.tableData.length;
      this.rows = this.tableData.length;
    },

    hiddenModals() {
      this.showModalModules = false;
      this.showModalClasses = false;
      this.showModalQuestionDissertation = false;
      this.showModalQuestionAttachment = false;
      this.showModalQuizzes = false;
    },

    editModule(module) {
      this.editingModule = module;
      this.showModalModules = true;
    }
  },
  mounted() {
    this.loadModules();
  },
};
</script>

<template>
  <section>
    <div class="buttons mb-4 col-xl-12 mb-4 text-center mt-3">
      <b-button
        class="m-1"
        pill
        variant="outline-primary"
        @click="showModalModules = !showModalModules"
        ><i class="mdi mdi-plus"></i> Adicionar Módulo</b-button
      >
      <b-button
        class="m-1"
        pill
        variant="outline-primary"
        @click="showModalClasses = !showModalClasses"
        ><i class="mdi mdi-plus"></i> Adicionar Aula</b-button
      >
      <b-button
        class="m-1"
        pill
        variant="outline-primary"
        @click="showModalQuestionDissertation = !showModalQuestionDissertation"
        ><i class="mdi mdi-plus"></i> Adicionar Questão Dissertativa</b-button
      >
      <b-button
        class="m-1"
        pill
        variant="outline-primary"
        @click="showModalQuestionAttachment = !showModalQuestionAttachment"
        ><i class="mdi mdi-plus"></i> Adicionar Questão Com Anexo</b-button
      >
      <b-button
        class="m-1"
        pill
        variant="outline-primary"
        @click="showModalQuizzes = !showModalQuizzes"
        ><i class="mdi mdi-plus"></i> Adicionar Questionario</b-button
      >
    </div>
    <div class="modules">
      <div class="col-xl-12">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" role="tab">
            <h6 class="m-0 font-14">
              <a
                v-b-toggle.accordion-1
                class="text-dark"
                href="javascript: void(0);"
                >Módulo 1: <b>Módulo 1</b></a
              >
            </h6>
          </b-card-header>
          <b-collapse
            id="accordion-1"
            visible
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card-text>Coisas do módulo</b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
    <b-modal
      v-model="showModalModules"
      id="modal-dialog"
      title="Cadastrar Módulo"
      title-class="font-18"
      hide-footer
    >
      <FormModuleCourse
        v-bind:editingModule="editingModule"
        v-bind:courseId="editingCourse?.id"
        @newCadSuccess="loadModules"
      ></FormModuleCourse>
    </b-modal>
    <b-modal
      v-model="showModalClasses"
      id="modal-dialog"
      title="Cadastrar Aulas"
      title-class="font-18"
      hide-footer
    >
      <FormLessonCourse @newCadSuccess="loadModules"></FormLessonCourse>
    </b-modal>
    <b-modal
      v-model="showModalQuestionDissertation"
      id="modal-dialog"
      title="Cadastrar Questão Dissertativa"
      title-class="font-18"
      hide-footer
    >
      <FormQuestionDissertationCourse
        @newCadSuccess="loadModules"
      ></FormQuestionDissertationCourse>
    </b-modal>
    <b-modal
      v-model="showModalQuestionAttachment"
      id="modal-dialog"
      title="Cadastrar Questão com Anexo"
      title-class="font-18"
      hide-footer
    >
      <FormQuestionAttachmentCourse
        @newCadSuccess="loadModules"
      ></FormQuestionAttachmentCourse>
    </b-modal>
    <b-modal
      v-model="showModalQuizzes"
      id="modal-dialog"
      title="Cadastrar Questionário"
      title-class="font-18"
      hide-footer
    >
      <FormQuizzesCourse @newCadSuccess="loadModules"></FormQuizzesCourse>
    </b-modal>
  </section>
</template>
