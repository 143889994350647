<script>
import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";
import { defineFormData, categoryMethods, notificationMethods } from "@/state/helpers";

import FormButtonSalvar from "@/components/forms/form-button-salvar/index";

/**
 * Category Form component
 */
export default {
  page: {
    title: "Formulário de Categorias",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { FormButtonSalvar },
  props: ["editingCategory"],
  data() {
    return {
      form: {
        title: "",
      },
      image: null,
      loading: false,
      submitted: false,
      loadingForm: false,
      submit: false,
    };
  },
  validations: {
    form: {
      title: { required },
      sequence: { required },
    },
  },
  methods: {
    ...categoryMethods,
    ...notificationMethods,

    handleFileChange(event) {
      this.image =  event.target.files[0];
    },

    async formSubmit() {
      this.loadingForm = true;
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        await this.saveCategory(
          defineFormData({ ...this.form, image: this.image ?? this.form.image }))
          .then(() => {
            this.loadingForm = false;
            this.emitCadSuccess(true);
        })
        .catch(() => {
            this.loadingForm = false;
        });
      } else {        
        this.loadingForm = false;
      }
    },
    emitCadSuccess(event) {
      this.$emit("newCadSuccess", event);
    },
    appendForm(category) {
      this.form = { ...category };
    },
  },
  watch: {
    editingCategory: function (category) {
      this.appendForm(category);
    },
  },
};
</script>

<template>
  <form class="needs-validation" @submit.prevent="formSubmit" v-if="!loading">
    <div class="row">
      <div class="col-lg-12">
        <div class="mb-3">
          <label class="form-label" for="validationCustom01"
            >Título da Categoria <span class="required">*</span></label
          >
          <input
            id="validationCustom01"
            v-model="form.title"
            type="text"
            class="form-control"
            placeholder="Digite o título da categoria"
            value="Mark"
            :class="{
              'is-invalid': submitted && $v.form.title.$error,
            }"
          />
          <div
            v-if="submitted && $v.form.title.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.form.title.required">O campo é obrigatório.</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="mb-3">
          <label class="form-label" for="validationCustom02"
            >Ordem <span class="required">*</span></label
          >
          <input
            id="validationCustom02"
            v-model="form.sequence"
            type="number"
            class="form-control"
            placeholder="Ex: 1"
            value="Otto"
            :class="{
              'is-invalid': submitted && $v.form.sequence.$error,
            }"
          />
          <div
            v-if="submitted && $v.form.sequence.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.form.sequence.required"
              >O campo é obrigatório.</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="mb-3">
          <label class="form-label" for="validationCustom02"
            >Imagem de Capa da Categoria (O tamanho da imagem deve ser: 400 X
            255)</label
          >
          <input
            id="validationCustom02"
            type="file"
            @change="handleFileChange"
            class="form-control"
            placeholder="Ex: 1"
            value="Otto"
          />
        </div>
      </div>
    </div>
    <FormButtonSalvar
      :parent="this"
      :loading="loadingForm"
      :text="form.id ? 'Salvar' : 'Cadastrar'"
    ></FormButtonSalvar>
  </form>
</template>
