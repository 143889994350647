<script>
import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";
import { moduleMethods, notificationMethods } from "@/state/helpers";

import FormButtonSalvar from "@/components/forms/form-button-salvar/index";

/**
 * Course Module Form component
 */
export default {
  page: {
    title: "Formulário de Módulos",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { FormButtonSalvar },
  props: ["courseId", "editingModule"],
  data() {
    return {
      form: {
        title: "",
        sequence: "",
        course_id: null,
      },
      loading: false,
      submitted: false,
      loadingForm: false,
      typesubmit: false,
      submit: false,
    };
  },
  validations: {
    form: {
      title: { required },
      sequence: { required },
    },
  },
  methods: {
    ...moduleMethods,
    ...notificationMethods,

    handleFileChange(event) {
      this.image =  event.target.files[0];
    },

    async formSubmit() {
      this.loadingForm = true;
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        await this.saveModule(this.form)
          .then(() => {
            this.loadingForm = false;
            this.emitCadSuccess(true);
        })
        .catch(() => {
            this.loadingForm = false;
        });
      } else {        
        this.loadingForm = false;
      }
    },
    emitCadSuccess(event) {
      this.$emit("newCadSuccess", event);
    },
    appendCourseId() {
      this.form = { ...this.form, course_id: this.courseId };
    },
    appendForm() {
      this.form = { ...this.form, ...this.editingModule };
    },
  },
  mounted() {    
    if (this.courseId) this.appendCourseId();
    if (this.editingModule) this.appendForm();
  },
};
</script>

<template>
  <form class="needs-validation" @submit.prevent="formSubmit" v-if="!loading">
    <div class="row">
      <div class="col-lg-12">
        <div class="mb-3">
          <label class="form-label" for="validationCustom01"
            >Título do módulo <span class="required">*</span></label
          >
          <input
            v-model="form.title"
            type="text"
            class="form-control"
            placeholder="Digite o título do módulo"
            value="Mark"
            :class="{
              'is-invalid': submitted && $v.form.title.$error,
            }"
          />
          <div
            v-if="submitted && $v.form.title.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.form.title.required">O campo é obrigatório.</span>
          </div>
        </div>
        <div class="mb-3">
          <label class="form-label" for="validationCustom01"
            >Ordem <span class="required">*</span></label
          >
          <input
            v-model="form.sequence"
            type="number"
            class="form-control"
            placeholder="Insira a ordem do módulo"
            value="Mark"
            :class="{
              'is-invalid': submitted && $v.form.sequence.$error,
            }"
          />
          <div
            v-if="submitted && $v.form.sequence.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.form.sequence.required">O campo é obrigatório.</span>
          </div>
        </div>
      </div>
    </div>
    <FormButtonSalvar
      :parent="this"
      :loading="loadingForm"
      :text="form.id ? 'Salvar' : 'Cadastrar'"
    ></FormButtonSalvar>
  </form>
</template>