var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 mb-3"},[_c('div',{staticClass:"mb-3"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.title),expression:"form.title"}],staticClass:"form-control",class:{
            'is-invalid': _vm.submitted && _vm.$v.form.title.$error,
          },attrs:{"type":"text","placeholder":"Digite o título da aula"},domProps:{"value":(_vm.form.title)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "title", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.title.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.title.required)?_c('span',[_vm._v("O campo é obrigatório.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"mb-3"},[_vm._m(1),_c('b-form-input',{class:{
            'is-invalid': _vm.submitted && _vm.$v.form.date.$error,
          },attrs:{"type":"date","id":"class-date","value":""},model:{value:(_vm.form.release_date),callback:function ($$v) {_vm.$set(_vm.form, "release_date", $$v)},expression:"form.release_date"}}),(_vm.submitted && _vm.$v.form.date.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.date.required)?_c('span',[_vm._v("Por favor, digite a data da aula.")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"mb-3"},[_vm._m(2),_c('multiselect',{class:{
            'is-invalid':
              _vm.typesubmit && _vm.$v.form.module.$error,
          },attrs:{"options":_vm.modules,"select-label":'Pressione Enter para Selecionar',"deselect-label":'Pressione Enter para Desselecionar',"placeholder":"Selecione o módulo da aula","label":"module","track-by":"id"},model:{value:(_vm.form.module),callback:function ($$v) {_vm.$set(_vm.form, "module", $$v)},expression:"form.module"}},[_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v("Não foram encontradas opções")])]),(_vm.submitted && _vm.$v.form.module.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.module.required)?_c('span',[_vm._v("Por favor, digite a data da aula.")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"class-date"}},[_vm._v("Anexar à Aula")]),_c('multiselect',{attrs:{"options":_vm.lessons,"select-label":'Pressione Enter para Selecionar',"deselect-label":'Pressione Enter para Desselecionar',"placeholder":"Selecione a aula para anexar","label":"module","track-by":"id"},model:{value:(_vm.form.lesson),callback:function ($$v) {_vm.$set(_vm.form, "lesson", $$v)},expression:"form.lesson"}},[_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v("Não foram encontradas opções")])])],1),_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"class-date"}},[_vm._v("Resumo")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.description),expression:"form.description"}],staticClass:"form-control",class:{
            'is-invalid':
              _vm.typesubmit && _vm.$v.form.description.$error,
          },attrs:{"name":"textarea"},domProps:{"value":(_vm.form.description)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "description", $event.target.value)}}})])])]),_c('FormButtonSalvar',{attrs:{"parent":this,"loading":_vm.loadingForm,"text":_vm.form.id ? 'Salvar' : 'Cadastrar'}})],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"form-label",attrs:{"for":"validationCustom01"}},[_vm._v("Título "),_c('span',{staticClass:"required"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"form-label",attrs:{"for":"class-date"}},[_vm._v("Data de Lançamento "),_c('span',{staticClass:"required"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"form-label",attrs:{"for":"class-date"}},[_vm._v("Módulo "),_c('span',{staticClass:"required"},[_vm._v("*")])])
}]

export { render, staticRenderFns }