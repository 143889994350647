<script>
/**
 * Form Button Salvar component
 */
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: "Cadastrar"
    }
  },
  methods: {
    emitCad(event) {
      this.$emit("salvar", event);
    },
  },
};
</script>

<template>  
  <span>
    <b-button type="submit" v-if="!loading" @click="emitCad(true)" variant="info" class="ms-1">
      <span>{{ text }}</span>
    </b-button>
    <b-button type="submit" v-if="loading" variant="light" class="ms-1">
      <b-spinner
        style="width: 15px; height: 15px;"
        label="Spinning"
        variant="info"
      ></b-spinner>
    </b-button>
  </span>
</template>
