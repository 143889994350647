<script>
import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";
import { defineFormData, sectionMethods, notificationMethods } from "@/state/helpers";

import Multiselect from "vue-multiselect";

import FormButtonSalvar from "@/components/forms/form-button-salvar/index";

/**
 * Course Question Attachment Form component
 */
export default {
  page: {
    title: "Formulário de Qeustionários do Curso",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { FormButtonSalvar, Multiselect },
  props: ["editingCourse"],
  data() {
    return {
      form: {
        title: "",
        release_date: "",
        external_id: "",
        type_id: "",
        lesson: "",
        description: "",
        advance: "",
        grade: "",
        statement: "",
      },
      modules: [],
      yesOrNotOptions: [
        {
          key: "Sim",
          value: true,
        },
        {
          key: "Não",
          value: false,
        },
      ],
      grades: [
        {
          key: "1",
          value: 1,
        },
        {
          key: "2",
          value: 2,
        },
        {
          key: "3",
          value: 3,
        },
        {
          key: "4",
          value: 4,
        },
        {
          key: "5",
          value: 5,
        },
        {
          key: "6",
          value: 6,
        },
        {
          key: "7",
          value: 7,
        },
        {
          key: "8",
          value: 8,
        },
        {
          key: "9",
          value: 9,
        },
        {
          key: "10",
          value: 10,
        },
      ],
      loading: false,
      submitted: false,
      loadingForm: false,
      typesubmit: false,
      submit: false,
    };
  },
  validations: {
    form: {
      title: { required },
    },
  },
  methods: {
    ...sectionMethods,
    ...notificationMethods,

    handleFileChange(event) {
      this.image =  event.target.files[0];
    },

    async formSubmit() {
      this.loadingForm = true;
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        await this.saveCategory(
          defineFormData({ ...this.form, image: this.image ?? this.form.image }))
          .then(() => {
            this.loadingForm = false;
            this.emitCadSuccess(true);
        })
        .catch(() => {
            this.loadingForm = false;
        });
      } else {        
        this.loadingForm = false;
      }
    },
    emitCadSuccess(event) {
      this.$emit("newCadSuccess", event);
    },
    appendForm(course) {
      this.form = { ...course };
    },
  },
  watch: {
    editingCourse: function (course) {
      this.appendForm(course);
    },
  },
};
</script>

<template>
  <form class="needs-validation" @submit.prevent="formSubmit" v-if="!loading">
    <div class="row">
      <div class="col-lg-12 mb-3">
        <div class="mb-3">
          <label class="form-label" for="validationCustom01"
            >Título do Questionário <span class="required">*</span></label
          >
          <input
            v-model="form.title"
            type="text"
            class="form-control"
            placeholder="Digite o título do questionário"
            :class="{
              'is-invalid': submitted && $v.form.title.$error,
            }"
          />
          <div
            v-if="submitted && $v.form.title.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.form.title.required">O campo é obrigatório.</span>
          </div>
        </div>
        <div class="mb-3">
          <label class="form-label" for="advance"
            >Avaliação Final <span class="required">*</span></label
          >
          <multiselect
            v-model="form.final"
            :options="yesOrNotOptions"
            :select-label="'Pressione Enter para Selecionar'"
            :deselect-label="'Pressione Enter para Desselecionar'"
            :no-options="'Lista Vazia'"
            :preselect-first="true"
            placeholder="Selecione uma opção"
            label="key"
            track-by="value"
          ></multiselect>
        </div>
        <div class="mb-3">
          <label class="form-label" for="grades"
            >Nota para Aprovação <span class="required">*</span></label
          >
          <multiselect
            v-model="form.grade"
            :options="grades"
            :select-label="'Pressione Enter para Selecionar'"
            :deselect-label="'Pressione Enter para Desselecionar'"
            :no-options="'Lista Vazia'"
            :preselect-first="true"
            placeholder="Selecione uma opção"
            label="key"
            track-by="value"
          ></multiselect>
        </div>
        <div class="mb-3">
          <label class="form-label" for="advance"
            >Avançar Curso <span class="required">*</span></label
          >
          <multiselect
            v-model="form.advance"
            :options="yesOrNotOptions"
            :select-label="'Pressione Enter para Selecionar'"
            :deselect-label="'Pressione Enter para Desselecionar'"
            :no-options="'Lista Vazia'"
            :preselect-first="true"
            placeholder="Selecione uma opção"
            label="key"
            track-by="value"
          ></multiselect>
        </div>
        <div class="mb-3">
          <label class="form-label" for="class-date"
            >Módulo <span class="required">*</span></label
          >
          <multiselect
            v-model="form.module"
            :options="modules"
            placeholder="Selecione o módulo da questão"
            label="module"
            track-by="id"
            :class="{
              'is-invalid':
                typesubmit && $v.form.module.$error,
            }"
          >
            <span slot="noOptions">Não foram encontradas opções</span>
          </multiselect>
          <div
            v-if="submitted && $v.form.module.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.form.module.required"
              >Por favor, digite a data da aula.</span
            >
          </div>
        </div>
        <div class="mb-3">
          <label class="form-label" for="advance"
            >Exibir Resposta Correta <span class="required">*</span></label
          >
          <multiselect
            v-model="form.show_correct"
            :options="yesOrNotOptions"
            :select-label="'Pressione Enter para Selecionar'"
            :deselect-label="'Pressione Enter para Desselecionar'"
            :no-options="'Lista Vazia'"
            :preselect-first="true"
            placeholder="Selecione uma opção"
            label="key"
            track-by="value"
          ></multiselect>
        </div>
        <div class="mb-3">
          <label class="form-label" for="advance"
            >Quantas Respostas Mostrar?</label
          >
          <b-form-input
            v-model="form.show_many"
            type="number"
            placeholder="0"
          ></b-form-input>
        </div>
        <div class="mb-3">
          <label class="form-label" for="advance"
            >Exibir Ajuda <span class="required">*</span></label
          >
          <multiselect
            v-model="form.help"
            :options="yesOrNotOptions"
            :select-label="'Pressione Enter para Selecionar'"
            :deselect-label="'Pressione Enter para Desselecionar'"
            :no-options="'Lista Vazia'"
            :preselect-first="true"
            placeholder="Selecione uma opção"
            label="key"
            track-by="value"
          ></multiselect>
        </div>                          
        <div class="mb-3">
          <label class="form-label" for="class-date"
            >Instrução</label
          >
          <textarea
            v-model="form.description"
            class="form-control"
            name="textarea"
            :class="{
              'is-invalid':
                typesubmit && $v.form.description.$error,
            }"
          ></textarea>
        </div>      
      </div>
    </div>
    <FormButtonSalvar
      :parent="this"
      :loading="loadingForm"
      :text="form.id ? 'Salvar' : 'Cadastrar'"
    ></FormButtonSalvar>
  </form>
</template>