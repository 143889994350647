var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"mb-3"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control",class:{
            'is-invalid': _vm.submitted && _vm.$v.form.name.$error,
          },attrs:{"id":"validationCustom01","type":"text","placeholder":"Digite o nome do autor","value":"Mark"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "name", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.name.required)?_c('span',[_vm._v("O campo é obrigatório.")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"mb-3"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.surname),expression:"form.surname"}],staticClass:"form-control",class:{
            'is-invalid': _vm.submitted && _vm.$v.form.surname.$error,
          },attrs:{"id":"validationCustom01","type":"text","placeholder":"Digite o sobrenome do autor","value":"Mark"},domProps:{"value":(_vm.form.surname)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "surname", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.surname.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.surname.required)?_c('span',[_vm._v("O campo é obrigatório.")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"validationCustom01"}},[_vm._v("Email ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control",attrs:{"id":"validationCustom01","type":"text","placeholder":"Digite o email do autor","value":"Mark"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "email", $event.target.value)}}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"mb-3"},[_vm._m(2),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.description),expression:"form.description"}],staticClass:"form-control",class:{
            'is-invalid': _vm.submitted && _vm.$v.form.description.$error,
          },attrs:{"name":"textarea","rows":"8"},domProps:{"value":(_vm.form.description)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "description", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.description.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.description.required)?_c('span',[_vm._v("O campo é obrigatório.")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"validationCustom02"}},[_vm._v("Imagem do Autor")]),_c('input',{staticClass:"form-control",attrs:{"id":"validationCustom02","type":"file","placeholder":"Ex: 1","value":"Otto"},on:{"change":_vm.handleFileChange}})])])]),_c('FormButtonSalvar',{attrs:{"parent":this,"loading":_vm.loadingForm,"text":_vm.form.id ? 'Salvar' : 'Cadastrar'}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"form-label",attrs:{"for":"validationCustom01"}},[_vm._v("Nome do Autor "),_c('span',{staticClass:"required"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"form-label",attrs:{"for":"validationCustom01"}},[_vm._v("Sobrenome "),_c('span',{staticClass:"required"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"form-label",attrs:{"for":"validationCustom02"}},[_vm._v("Biografia "),_c('span',{staticClass:"required"},[_vm._v("*")])])
}]

export { render, staticRenderFns }