var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"mb-3"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.title),expression:"form.title"}],staticClass:"form-control",class:{
            'is-invalid': _vm.submitted && _vm.$v.form.title.$error,
          },attrs:{"id":"validationCustom01","type":"text","placeholder":"Digite o título da categoria","value":"Mark"},domProps:{"value":(_vm.form.title)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "title", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.title.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.title.required)?_c('span',[_vm._v("O campo é obrigatório.")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"mb-3"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sequence),expression:"form.sequence"}],staticClass:"form-control",class:{
            'is-invalid': _vm.submitted && _vm.$v.form.sequence.$error,
          },attrs:{"id":"validationCustom02","type":"number","placeholder":"Ex: 1","value":"Otto"},domProps:{"value":(_vm.form.sequence)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "sequence", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.sequence.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.sequence.required)?_c('span',[_vm._v("O campo é obrigatório.")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"validationCustom02"}},[_vm._v("Imagem de Capa da Categoria (O tamanho da imagem deve ser: 400 X 255)")]),_c('input',{staticClass:"form-control",attrs:{"id":"validationCustom02","type":"file","placeholder":"Ex: 1","value":"Otto"},on:{"change":_vm.handleFileChange}})])])]),_c('FormButtonSalvar',{attrs:{"parent":this,"loading":_vm.loadingForm,"text":_vm.form.id ? 'Salvar' : 'Cadastrar'}})],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"form-label",attrs:{"for":"validationCustom01"}},[_vm._v("Título da Categoria "),_c('span',{staticClass:"required"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"form-label",attrs:{"for":"validationCustom02"}},[_vm._v("Ordem "),_c('span',{staticClass:"required"},[_vm._v("*")])])
}]

export { render, staticRenderFns }